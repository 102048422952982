import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import { ReactSVG } from 'react-svg'
import { Link } from 'gatsby'

import SEO from '@/helpers/SEOHelper.js'
import Layout from '@/components/Layout'

export const PostTemplate = ({ project, content }) => {
  return (
    <>
      <section className='Post'>
        <div className='container' data-aos='fade-right' data-aos-delay='100'>
          <Link className='DeliverablePage__link' to='/news'>
            <ReactSVG src='/img/arrow.svg'></ReactSVG>
            <span>Back to news</span>
          </Link>
        </div>
        {project.image ? (
          <GatsbyImage
            image={getImage(project.image)}
            className='Post__background background '
          />
        ) : (
          ''
        )}
        <div className='container pt-32 pb-32'>
          <div className='Post__hero'>
            <div className='Post__title'>
              <h2 className='title-6 text-accent' data-aos='fade-right'>
                {project.date}
              </h2>
              <h1 className='mb-40' data-aos='fade-right' data-aos-delay='50'>
                {project.title}
              </h1>
            </div>
            {project.image ? (
              <div
                className='Post__thumbnail'
                data-aos='fade-up'
                data-aos-delay='100'>
                <GatsbyImage image={getImage(project.image)} />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className='container'>
          <div className='Post__content'>
            <div data-aos='fade-up' data-aos-delay='100'>
              {ReactHtmlParser(content)}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const PostPage = ({ data }) => {
  const { frontmatter: post } = data.post
  const content = data.post.html
  const seo = post.seo

  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta
          property='og:title'
          content={SEO.title(seo.title || post.title)}
        />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta
          property='og:image'
          content={SEO.image(seo?.image?.publicURL || post?.image?.publicURL)}
        />
        <title>{SEO.title(seo.title || post.title)}</title>
      </Helmet>
      <PostTemplate project={post} content={content} />
    </Layout>
  )
}

export default PostPage

export const PostPageQuery = graphql`
  query PostPage($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 45
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
      html
    }
  }
`
